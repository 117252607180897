.CoursePage {
  padding: 20px;
}

.PageTitle {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.CourseList {
  display: grid;
  gap: 20px;
}

.CourseCard {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  grid-column: 1 / -1; /* Span across all columns */
}

.CourseTitle {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.CourseDescription {
  margin-bottom: 20px;
}

.StartButton {
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff;
  color: #ffffff;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s ease-in-out;
}

.StartButton:hover {
  background-color: #0056b3;
}

.CompletionStatus {
  font-size: 14px;
  margin-bottom: 10px;
}

/* Define styles for different completion statuses */
.CompletionStatus.Completed {
  color: green;
}

.CompletionStatus.InProgress {
  color: orange;
}

.CompletionStatus.NotStarted {
  color: red;
}

.Completed {
  color: #02c57e;
}

.Midway {
  color: #F6BE00;
}