.NavBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #333;
  padding: 16px;
  color: #fff;
  text-align: center;
}

.LogoText {
  font-weight: bold;
  font-size: 190%;
  background: linear-gradient(to right, #1c87c9 30%, #8ebf42 40%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-decoration: none; /* Add this line to remove underline */
}

.LogoText a {
  text-decoration: none; /* Add this line to remove underline */
}

.LogoText a:hover {
  text-decoration: none; /* Add this line to remove underline on hover */
}

.Logo {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #fff;
}

.Logo img {
  width: 32px; /* Adjust the width as needed */
  height: 32px; /* Adjust the height as needed */
  margin-right: 8px; /* Adjust the spacing between logo and title as needed */
}



.DarkBlue {
  background: linear-gradient(to right, #1c87c9 30%, #1c87c9 40%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.LightBlue {
  background: linear-gradient(to right, #8ebf42 30%, #8ebf42 40%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.NavLinks {
  list-style-type: none;
  display: flex;
  gap: 16px;
  justify-content: center; /* Center the navigation links horizontally */
  align-items: center; /* Center the navigation links vertically */
}

.NavLink {
  cursor: pointer;
}

.NavLink a {
  color: #fff;
  text-decoration: none;
}

.NavLink a:hover {
  color: #ffac33;
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
  .NavBar {
    flex-direction: column;
    align-items: flex-start;
  }

  .NavLinks {
    margin-top: 16px;
    justify-content: flex-start;
  }

  .NavLink {
    margin-right: 16px;
  }
}
