.About {
    padding: 40px;
  }
  
  .AboutTitle {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .AboutContent {
    font-size: 18px;
    line-height: 1.5;
  }
  