.CoursePage {
  justify-content: center;
  margin-top: 20px;
}

.CourseContainer {
  display: flex;
  gap: 20px;
}

.ProgressBar {
  width: 100%;
  height: 20px;
  background-color: #ddd;
  border-radius: 10px;
  overflow: hidden;
  position: relative; /* Added */
}

.ProgressFill {
  height: 100%;
  background-color: #4caf50;
  transition: width 0.3s ease-in-out;
  position: relative; /* Added */
}

.ProgressText {
  position: absolute; /* Added */
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  line-height: 20px;
  color: #fff;
  font-size: 12px;
}



.Sidebar {
  width: 25%;
  background-color: whitesmoke;
  border-radius: 20px;
  text-align: center; 
}

.SidebarTitle {
  font-size: 20px;
}

.ChapterList {
  list-style: none;
  padding: 0;
}

.ChapterButton {
  display: block;
  position: relative;
  width: 100%;
  background-color: #f2f2f2;
  border: none;
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;
  text-align: left;
  font-size: 16px;
  color: #333;
}

.ChapterButton.active {
  background-color: #ddd;
}

.NextButton {
  text-decoration: none;
  display: inline-block;
  padding: 8px 16px;
  background-color: #04aa6d;
  color: whitesmoke;
  width: 100%;
  border: 0px;
  border-radius: 10px;
  cursor: pointer;
}

.NextButton:hover {
  background-color: #038656;
  color: whitesmoke;
}

.ChapterButton.completed {
  background-color: #c7e2c7;
}

.ChapterButton .CompletionIcon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  color: green;
}

.Content {
  width: 60%;
  margin: auto;
}


.ChapterDetails h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.VideoWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.VideoItem {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
}

.VideoItem iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 768px) {
  .CourseContainer {
    flex-direction: column;
  }

  .Sidebar {
    flex: none;
    width: 100%;
    text-align: left;
    background-color: white;
  }
  

  .ChapterList {
    display: none;
  }

  .Content {
    order: 2;
    width: 95%;
  }

  .NextButton {
    margin-top: 20px;
  }

  .dropdown {
    width: 100%;
    background-color: whitesmoke;
    border: none;
  }
}
