.Hero {
  background-color: #333;
  color: #fff;
  padding: 80px 0;
  text-align: center;
}

.HeroContent {
  max-width: 800px;
  margin: 0 auto;
}

.HeroTitle {
  font-size: 40px;
  font-weight: bold;
}

.HeroSubtitle {
  font-size: 24px;
}

/* Media Queries */
@media screen and (max-width: 768px) {
  /* Adjust styles for screens up to 768px wide */
  .HeroContent {
    max-width: 90%;
  }

  .HeroTitle {
    font-size: 32px;
  }

  .HeroSubtitle {
    font-size: 20px;
  }
}

@media screen and (max-width: 480px) {
  /* Adjust styles for screens up to 480px wide */
  .HeroContent {
    padding: 40px;
  }

  .HeroTitle {
    font-size: 28px;
  }

  .HeroSubtitle {
    font-size: 18px;
  }
}
